/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;


.main-content-shadow {
  box-shadow: 0px 4px 8px rgba(72, 86, 101, 0.16), 0px 1px 4px rgba(72, 86, 101, 0.24);
}

@layer base{
  @font-face {
    font-family:'Neusa';
    src: url('../../public/font/NeusaNextProWideBold.woff2') format('woff2')
  }
  @font-face {
    font-family:'Proxima';
    src: url('https://cdn.f5.com/websites/support/assets/fonts/ProximaRegular.woff') format('woff')
  }
  html {
    font-family : 'Proxima';
  }
}